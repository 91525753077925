<template>
  <validation-observer ref="formOwner">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      :disabled="isDetailPage"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                v-if="isAddPage"
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                  >Password</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    value="p@ssword123"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="phone_number"
                  >No Telepon</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="No. telepon"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="models.phone"
                      :disabled="isDetailPage"
                      type="tel"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="tag"
                  >Tags</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Tag"
                    rules="required"
                  >
                    <v-select
                      id="tag"
                      v-model="models.since"
                      label="name"
                      :disabled="isDetailPage"
                      placeholder="- Cari Tag -"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.regencies"
                      :reduce="option => option"
                      @search="debounceSearch"
                      @input="setTagInfo"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Tidak ditemukan hasil pencarian
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    v-if="isDetailPage"
                    id="status"
                    :disabled="isDetailPage"
                    type="text"
                    :value="models.status ? 'Aktif' : 'Tidak Aktif'"
                  />
                  <div
                    v-else
                    class="col-sm-6 d-flex justify-content-between"
                  >
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">

            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              {{ isDetailPage ? 'Kembali' : 'Batal' }}
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'

export default {
  name: 'FormOwner',
  metaInfo: {
    title: 'Form Owner',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        name: '',
        phone: '',
        email: '',
        since: '',
        status: true,
      },
      masters: {
        regencies: [],
      },
      required,
      email,
      numeric,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-owner'
    },
    isAddPage() {
      return this.$route.name === 'add-owner'
    },
  },
  created() {
    this.getListOption()
    this.getById()

    // getInitialTags
    this.$axios.get(api.list_regency).then(res => {
      this.masters.regencies = res.data.data.rows
    })
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.owners}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.name = data.profile.fullName
            this.models.phone = data.profile.phone
            this.models.email = data.email
            this.models.since = data.profile.since
            this.models.status = data.status
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    getListOption() {
      const getOwners = this.$axios.get(`${api.list_owner}`)
      const getLevelKFS = this.$axios.get(`${api.level}`)

      Promise.all([getOwners, getLevelKFS]).then(res => {
        const [owners, levels] = res
        owners.data.data.forEach(data => {
          data.text = data.profile.fullName
          this.masters.owners.push(data)
        })

        levels.data.data.rows.forEach(data => {
          this.masters.levels.push(data)
        })
      })
    },
    setAddress(data) {
      const { address, lat, lng } = data
      this.models.lat = lat.toString()
      this.models.long = lng.toString()
      if (address) {
        this.models.address = address
      }
    },
    setTagInfo(data) {
      this.models.since = data.name
    },
    debounceSearch(value) {
      if (value !== '') {
        this.models.since = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.regencies = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_regency}?search=${this.models.since}`)
      return res.data.data.rows
    },
    save() {
      this.$refs.formOwner.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.owners, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.owners}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} owner`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
